import React from 'react'
import Header from '../pages/Header'
import ServiceBanner from './ServiceBanner'
import Footer from '../pages/Footer'


function Service() {
  return (
    
    <div>
        <Header/>
        <ServiceBanner/>
        <Footer/>
    </div>
  )
}

export default Service