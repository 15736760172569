import { Box, Container, Grid, Typography } from "@mui/material";
import OurTeamCard from "./OurTeamCard";
import OurteamData from "./WhyChooseData";



function Ourteam() {
    return (
        <>
            <Box className="ourteam-sec">
                <Container>
                    <Grid Container>
                        <Box className="whychoose_heading">
                            <Typography variant="h2">Our Services</Typography>
                            <Typography>At Hind Electrical, we pride ourselves on delivering unparalleled electrical solutions tailored to meet your unique needs. With years of industry expertise and a commitment to excellence, we ensure that your electrical systems operate seamlessly, providing safety, efficiency, and reliability. </Typography>
                        </Box>
                    </Grid>

                    <Grid container className="whychoose_card">

            {OurteamData.OurteamData.map((valu) => {
              return (
                <OurTeamCard
                  key={valu.id}
                  teamimage={valu.teamimage}
                  teamname={valu.teamname}
                  profilename={valu.profilename}
                  valu={valu}
                />
              );
            })}

            </Grid>


                </Container>
            </Box>

        </>
    );
};


export default Ourteam;