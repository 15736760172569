
import { Router } from 'react-router-dom';
import Layout from './All-components/pages/Layout';
import React from "react";
import { Route, Routes } from "react-router-dom";
import Contact from './All-components/menupages/Contact';
import Gallery from './All-components/menupages/Gallery';
import About from './All-components/menupages/About';
import Categories from './All-components/Landing-page/Categories';
import WhyChoose from './All-components/Landing-page/Why-choose-us';
import Business from './All-components/Landing-page/Business';
import Clientreview from './All-components/Landing-page/Clientreview';
import Ourteam from './All-components/Landing-page/Ourteam';
import Appoinment from './All-components/Landing-page/Appoinment';
import Consulting from './All-components/Landing-page/Consulting';
import Service from './All-components/services/Service';




function App() {

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Layout />}/>
        {/* <Route index element={<WhyChoose />}/> */}
        
            {/* <Business />
            <Clientreview />
            <Ourteam />
            <Appoinment />
            <Consulting /> */}
        <Route path='/about' element={<About/>}></Route>
        <Route path='/gallery' element={<Gallery/>}></Route>
        <Route path='/service' element={<Service/>}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
        <Route path='/categories' element={<Categories/>}></Route>
      </Routes>
    </div>
  );
}

export default App;
