import React, { useState } from 'react';
import StarIcon from '@mui/icons-material/Star';
import { Box, Card, Grid, IconButton, Typography } from '@mui/material';
import StarHalfIcon from '@mui/icons-material/StarHalf';

function ClientReviewDesign(props) {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <Grid lg={4} md={4} xs={12} sx={{ p: 2 }}>
                <Card className="client_row">
                    <Box className="client_rating">
                        <IconButton>
                            <StarIcon />
                        </IconButton>
                        <IconButton>
                            <StarIcon />
                        </IconButton>
                        <IconButton>
                            <StarIcon />
                        </IconButton>
                        <IconButton>
                            <StarHalfIcon />
                        </IconButton>
                    </Box>

                    <Typography className='client_subtext'>
                        {expanded ? props.revietext : `${props.revietext.slice(0, 150)}... `}
                        {!expanded && (
                            <button onClick={toggleExpanded} style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', color: 'blue' }}>
                                Read More
                            </button>
                        )}
                        {expanded && (
                            <button onClick={toggleExpanded} style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', color: 'blue' }}>
                                Read Less
                            </button>
                        )}
                    </Typography>
                    <Box className="client_detail" sx={{ display: 'flex', alignItems: "center" }}>
                        <img src={props.clientimage} alt='client image' style={{width:"25%", height:"11vh", borderRadius:"50px"}}/>
                        <Box className="client_name_detail">
                            <Typography>{props.clientname}</Typography>
                            {/* <Typography style={{ color: "#000" }}>{props.profile}</Typography> */}
                        </Box>
                    </Box>
                </Card>
            </Grid>
        </>
    );
};

export default ClientReviewDesign;
