
import Header from "./Header";
import WhyChoose from "../Landing-page/Why-choose-us";
import Clientreview from "../Landing-page/Clientreview";
import Ourteam from "../Landing-page/Ourteam";
import Appoinment from "../Landing-page/Appoinment";
import Consulting from "../Landing-page/Consulting";
import Footer from "./Footer";
import Business from "../Landing-page/Business";
import { Outlet } from "react-router-dom";
import Banner from "../Landing-page/Banner";


function Layout() {
    return (
        <>
            <Header />
               
      {/* =====banner-sec=== */}
      <Banner />
      {/* =====banner-sec=end=== */}
            <Outlet/>
            <WhyChoose />
            {/* <Business /> */}
            <Clientreview />
            <Ourteam />
            <Appoinment />
            <Consulting />
            <Footer />
        </>
    );
};

export default Layout;