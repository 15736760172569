import { Box, Container, Grid, Typography } from "@mui/material";
import ClientData from "../Landing-page/WhyChooseData";
import Whychooselist from "./Whychooselist";
import ClientReviewDesign from "../Landing-page/ClientReviewDesign";


function Clientreview() {
  return (
    <>
      <Box className="business-sec">
        <Container>
          <Grid Container>
            <Box className="whychoose_heading clientreview_heading">
              <Typography variant="h2">What Clients Say</Typography>
              <Typography>Hind Electrical has consistently provided exceptional services, exceeding our expectations. Their team is not only knowledgeable but also responsive,<br/>
               ensuring that our electrical needs are met promptly and efficiently. We appreciate their commitment to quality and reliability, making them our trusted partner for all electrical solutions. Highly recommended for their top-notch services and customer-centric approach! </Typography>
            </Box>
          </Grid>

          <Grid container className="whychoose_card">

            {ClientData.ClientData.map((valu) => {
              return (
                <ClientReviewDesign
                  key={valu.id}
                  revietext={valu.revietext}
                  clientimage={valu.clientimage}
                  clientname={valu.clientname}
                  profile={valu.profile}
                />
              );
            })}

          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Clientreview;