
import Menuheader from "./Menuheader";
import Footer from '../pages/Footer';
import GallerySecOne from "./GallerySecOne";

// import "/src/style.css"


 function About(){
    return(
        <>
          <Menuheader />
          <GallerySecOne/>
          <Footer/>
        </>
    )
 };

 export default About;