

import Footer from '../pages/Footer';
import Header from '../pages/Header';
import ContactBanner from './ContactBanner';
import ContactDetails from "./ContactDetails";


// import "/src/style.css"


 function About(){
    return(
        <>
        <Header/>
        <ContactBanner/>
          <ContactDetails/>
          <Footer/>
        </>
    )
 };

 export default About;