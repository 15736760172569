import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";


function Consulting() {
    const navigate = useNavigate();
    return (
        <>
            <Box className="consulting-sec">
                <Container>
                    <Grid container >
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                                <Box className="consulting_heading">
                                    <Typography variant="h3">Get in Touch Today for Expert Electrical Solutions</Typography>
                                    <Typography>Let our experienced team handle all your electrical needs with professionalism and reliability.</Typography>
                                </Box>
                                <Box>
                                    <Button variant="contained" className="banner-form book-button" onClick={()=>{navigate("/contact")}}>Contact Us</Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default Consulting;