import AboutSecOne from "./AboutSecOne";
// import Menuheader from "./Menuheader";
import Footer from '../pages/Footer';
import Header from "../pages/Header";
import Breadcrum from "./Breadcrum";


// import "/src/style.css"


 function About(){
    return(
        <>
          <Header />
             
      {/* =====banner-sec=== */}
      <Breadcrum />
      {/* =====banner-sec=end=== */}
          <AboutSecOne/>
          <Footer/>
        </>
    )
 };

 export default About;