

const WhyChooseData = [
    {
    id: 1,
    cardimg: "/images/whychoose-img-01.png",
    cardtitle: "Expertise",
    list1: "- Hind Electrical brings unparalleled expertise to every project.",
    list2: "- With years of experience in the industry.",
    list3: "- Our team of skilled professionals.",
    list4: "- Our team is well-versed .",
    },
    {
        id: 2,
        cardimg: "/images/whychoose-img-02.png",
        cardtitle: "Timely Delivery",
        list1: "- Hind Electrical is dedicated to delivering products and services.",
        list2: "- We understand the importance of timely project completion",
        list3: "- Work Satisfaction.",
        list4: "- Helping your project deadlines.",
        },
        {
            id: 2,
            cardimg: "/images/whychoose-img-03.png",
            cardtitle: "Services",
            list1: "-  Product installation and maintenance,",
            list2: "- Hind Electrical provides best electrical solutions.",
            list3: "- Our services ensure a seamless experience for our clients.",
            
            },
    

 
];

const ClientData = 
    [
        {
        id: 1,
        revietext: "I've been a customer of Hind Electrical for several years now, and they have consistently delivered exceptional service. Whether it's routine maintenance or addressing an electrical issue, their team is always prompt and efficient. Their commitment to quality and customer satisfaction makes them my go-to for all electrical needs.",
        clientimage: "/images/client-1.jpeg",
        clientname: "Faiz Khan",
        profile: "Client",

    },
    {
        id: 2,
        revietext: "Hind Electrical is simply the best in the business! I contacted them for an emergency electrical repair, and they responded quickly and resolved the issue with precision. The technicians were courteous and explained the process thoroughly. I appreciate their professionalism and would give them five stars for their excellent service.",
        clientimage: "/images/clientOne.png",
        clientname: "Stella Wiliam",
        profile: "Client",

    },
    {
        id: 3,
        revietext: "The team was professional, completed the work on time, and the results were flawless. I highly recommend Hind Electrical for their expertise and reliability.",
        clientimage: "/images/clienttwo.png",
        clientname: "Regina Miles",
        profile: "Client",

    },

]

const OurteamData = [
    {
        id: 1,
        teamimage: "/images/water-pumps.png",
        teamname: "Water Pumps",
        profilename: "Single Phase / Three Phase Motor",
        categorie:"water-pumps"
    },
    {
        id: 2,
        teamimage: "/images/stabilizer.jpeg",
        teamname: "Voltage Stabilizer",
        profilename: "Automatic /  Manual",
        categorie:"voltage-stabilizer"
    },
    {
        id: 3,
        teamimage: "/images/generator.jpg",
        teamname: "Generators",
        profilename: "Diesel Fuel Generators",
        categorie:"stabilizer"
    },
]

const GalleryData = [
    {
        id: 1,
        img: "images/_photobylotte)_1427577185.jpeg"
    },
    {
        id: 2,
        img: "images/04d1b0f960568ab49f36befff9282397-2.jpg"
    },
    {
        id: 3,
        img: "images/382656_2412699795172_314272330_n.jpg"
    },
    {
        id: 4,
        img: "images/382656_2412699795172_314272330_n.jpg"
    },
    {
        id: 5,
        img: "images/382656_2412699795172_314272330_n.jpg"
    },
    {
        id: 6,
        img: "images/04d1b0f960568ab49f36befff9282397-2.jpg"
    },
    
];


 export default {WhyChooseData, ClientData, OurteamData, GalleryData};

//  export default {WhyChooseData: WhyChooseData, ClientData:ClientData};  (it's working)
