import { Box, Button, Container, Grid, TextField, Typography } from "@mui/material";


export default function ContactDetails() {
    return (
        <>
            <Box className="whychoose-sec">
                <Container>
                    <Grid Container>
                        <Box className="whychoose_heading">
                            <Typography variant="h2">Contact US</Typography>
                            <Typography>Connect with Hind Electricals for Expert Electrical Services. From Repairs to Installations, We're Dedicated to Excellence. With Years of Experience and a Passion for Innovation, Trust Us to Light Up Your World. Contact Us Today and Let's Start Building a Brighter Future Together! </Typography>
                        </Box>
                    </Grid>

                    <Grid container className="contact_box">
                        <Grid lg={6} md={6} xs={12}>
                            <Box className="contact_details">
                                <TextField id="outlined-name" fullWidth sx={{ m: 1, display:{sm:"block", width:"95%"}}} label="Name" variant="outlined" />
                                <TextField id="outlined-email" fullWidth sx={{ m: 1, display:{sm:"block", width:"95%"} }} label="Email" variant="outlined" />
                                <TextField id="outlined-number" fullWidth sx={{ m: 1,display:{sm:"block", width:"95%"} }} label="Phone Number" variant="outlined" />
                                <TextField id="outlined-query" fullWidth sx={{ m: 1, display:{sm:"block", width:"95%"} }} label="Description" variant="outlined" />
                            </Box>
                            <Box className="contact_button" >
                              <Button variant="contained">submit</Button>
                            </Box>
                        </Grid>
                        
                        
                    </Grid>
                </Container>
            </Box>
        </>
    )
};